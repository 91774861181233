import React , {useEffect} from 'react'
import './About.css';
import { SchoolOutlined , PersonOutlined , PhoneOutlined , LocationOnOutlined } from '@material-ui/icons';
import langs from '../languages';
import determineStyle from '../utils';
import Aos from 'aos';
import "aos/dist/aos.css";


const About = () => {


  useEffect(() => {
    Aos.init( {  duration: 1200 } )
  } , []);

  return (
    <div id="about" className='about_me_page' style={{ height: '100vh' , background: "linear-gradient(180deg, rgb(0 ,0 ,0 , 0.6) 0%, rgba(18, 20, 33, 0.5) 100%) ,url('https://firebasestorage.googleapis.com/v0/b/notregsyrdev.appspot.com/o/wallpaperflare.com_wallpaper.jpg?alt=media&token=a3f23562-8d9b-49dd-aee6-dadd3438bdc0') center / cover" }} >
      <div className="d-grid grid_about container-mid flex-col">
        <div className="g-col-2">

          <div className="about_picture">
          <img
          data-aos="fade-right"
          data-aos-easing="ease-in-cubic"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-anchor-placement="top-center"

           src="https://i.ibb.co/d0y2DGV/IMG-1340.jpg" className='box avatar_regsyr_big' alt="" />
          </div>
          <div className="about_info">
            <button data-aos="fade-down-left"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-anchor-placement="top-center" className="about_me_btn hover-1" style={ { fontFamily: determineStyle('heading') } }  > {langs[localStorage.getItem("language")][2].title } </button>
            <p className="mt-1 mb-2 lead_para" style={ { fontFamily: determineStyle('para') } }  >
            {langs[localStorage.getItem("language")][2].desc }
            </p>
            <div className=" d-flex-c f-sp about_info_div">

              <div  className='flex-col-l' >
              <h4 className="about_info_headline" style={ { fontFamily: determineStyle('heading') } }  > <PersonOutlined className="icon_about_outlined" />   {langs[localStorage.getItem("language")][2].about_1 } </h4>
              <h4 className="about_info_subheadline" style={ { fontFamily: determineStyle('para') } } >   {langs[localStorage.getItem("language")][2].value_1 } </h4>
              </div>

              <div className='flex-col-l' >
              <h4 className="about_info_headline" style={ { fontFamily: determineStyle('heading') } } > <SchoolOutlined className="icon_about_outlined" />    {langs[localStorage.getItem("language")][2].about_2 } </h4>
              <h4 className="about_info_subheadline" style={ { fontFamily: determineStyle('para') } } >   {langs[localStorage.getItem("language")][2].value_2 } </h4>
              </div>

            </div>


          </div>

        </div>
      </div>
    </div>
  )
}

export default About