import React , {useEffect , useState} from 'react';
import langs from '../languages';
import determineStyle from '../utils';
import Aos from 'aos';
import "aos/dist/aos.css";
import './MySharings.css';
import SharingOne from './Sharings/SharingOne';
import SharingTwo from './Sharings/SharingTwo';
import SharingThree from './Sharings/SharingThree';
import SharingFour from './Sharings/SharingFour';
import SharingFive from './Sharings/SharingFive';
import SharingSix from './Sharings/SharingSix';
import SharingSeven from './Sharings/SharingSeven';
import SharingEight from './Sharings/SharingEight';
import SharingNine from './Sharings/SharingNine';
import SharingTen from './Sharings/SharingTen';
import SharingEleven from './Sharings/SharingEleven';




const MySharings = () => {

  const [indexSlide , setIndexSlide] = useState(1);

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
      };

      useEffect(() => {
        Aos.init( {  duration: 1200 } )
      } , []);




      const handleSliding = (argument) => {

        if (indexSlide == 0) {
          setIndexSlide(1);
        }

          if (indexSlide <= 0  ||  indexSlide >= 11 ) {
              setIndexSlide(1);
          }
          else {

              setIndexSlide(indexSlide + argument);
          }


      }

      function determineComponent(  )  {

          switch(indexSlide) {
              case 1:
                  return <SharingOne />;

              case 2:
                  return <SharingTwo />;

              case 3:
                  return <SharingThree />;

              case 4:
                  return <SharingFour />;

              case 5:
                  return <SharingFive />;

              case 6:
                  return <SharingSix />;

              case 7:
                  return <SharingSeven />;

              case 8:
                  return <SharingEight />;

              case 9:
                  return <SharingNine />;

              case 10:
                  return <SharingTen />;

              case 11:
                  return <SharingEleven />;

          }
      }


  return (
    <div id="mysharings" className='services_page' style={{ height: 'auto'   , paddingBottom: '3.5rem' , background: "linear-gradient(180deg, rgb(16, 35, 23 , 0.4) 0%, rgba(28, 38, 38, 0.5) 100%) ,url('https://firebasestorage.googleapis.com/v0/b/notregsyrdev.appspot.com/o/wallpaperflare.com_wallpaper.jpg?alt=media&token=a3f23562-8d9b-49dd-aee6-dadd3438bdc0') center / cover" }}   >

    <div className="text-center container-sm">
    <div className="pt-5"></div>
     <h1
     data-aos="flip-up"
          data-aos-easing="linear"
          data-aos-mirror="true"
          data-aos-once="false"
      className="headline_regsyr hover-1" style={ { fontFamily: determineStyle('heading') } }  > مشاركاتي </h1>
     <div className="mt-5"></div>
     <div className="d-grid grid_hire">

       <div className="g-col-2">


       {determineComponent()}

       </div>

       <div className="what_idone_div">



<div className="slidings_divs icons_section">

<img src="/images/left-arrow.png" alt="" className='sharing_slide_arrow' onClick={() => handleSliding(+1) } />

{indexSlide > 1 && (
  <img src="/images/right-arrow.png" className='sharing_slide_arrow' alt="" onClick={() => handleSliding(-1) } />
)}

</div>

</div>


     </div>
    </div>
 </div>
  )
}

export default MySharings