import React , { useEffect , useState , useRef }from 'react';
import {  Section } from 'react-fully-scrolled';
import Header from '../components/Header';
import HeroArea from '../components/HeroArea';
import About from '../components/About';
import Services from '../components/Services';
import Projects from '../components/Projects';
import HireMe from '../components/HireMe';
import ContactMe from '../components/ContactMe';
import Loading from '../components/Loading';
import MyExpereince from '../components/MyExpereince';
import MySharings from '../components/MySharings';







const Home = () => {

  const heroRef = useRef(null)


  const [loadingWeb , setLoadingWeb] = useState(true);

  const executeHeroScroll = () => heroRef.current.scrollIntoView()



  useEffect(() => {
    if (localStorage.getItem("language") === null) {
      localStorage.setItem('language', 1 );
    }

    setTimeout(() => {
      setLoadingWeb(false);
    } , 1500 );

  } , [localStorage.getItem("language")])


  return (

    <div  >
    { /*  RIGHT CORNCER*/ }
    {loadingWeb ? (
      <Loading />
    ) : (
      <div>

      <Header    />

      <Section>
      <HeroArea  />
      </Section>

      <Section >
      <About  />
      </Section>

      <Section>
      <Projects  />
      </Section>

      <Section>
      <MyExpereince />
      </Section>

      <Section>
      <MySharings />
      </Section>

      <Section>
      <Services />
      </Section>

      <Section>
      <HireMe  />
      </Section>

      <Section>
      <ContactMe  />
      </Section>

      </div>
    )}



  </div>


  )
}

export default Home