import React , {useRef , useState , useEffect} from 'react';
import './ContactMe.css';
import langs from '../languages';
import determineStyle from '../utils';
import emailjs from '@emailjs/browser';
import Aos from 'aos';
import "aos/dist/aos.css";

const ContactMe = () => {

  let textSended = '';

  const [emailSended , setEmailSended] = useState(false);
  const [sucess , setSuccess] = useState(1);
  const [nameText , setNameText  ] = useState('');
  const [emailText , setEmailText  ] = useState('');
  const [messageText , setMessageText  ] = useState('');

  useEffect(() => {
    Aos.init( {  duration: 1200 } )
  } , []);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();


    emailjs.sendForm( process.env.REACT_APP_SERVICE_ID , process.env.REACT_APP_TEMPLATE_ID, form.current,  process.env.REACT_APP_PUBLIC_KEY )
      .then((result) => {

        setEmailSended(true);
        setSuccess(1);

        setNameText('');
    setEmailText('');
    setMessageText('');

        setTimeout(() => {
          setEmailSended(false);
          window.location.reload();
        }, 3500);


      } , (error) => {
        setEmailSended(true);
        setSuccess(0)

        setNameText('');
    setEmailText('');
    setMessageText('');

        setTimeout(() => {
          setEmailSended(false);
          window.location.reload();
        }, 3500);
      });
  };

  return (
    <div id="contact" className='services_page' style={{ height: '100vh' , background: "linear-gradient(180deg, rgb(15, 15, 15 , 0.8) 0%, rgba(14, 20, 18, 0.8) 100%) ,url('https://firebasestorage.googleapis.com/v0/b/notregsyrdev.appspot.com/o/wallpaperflare.com_wallpaper.jpg?alt=media&token=a3f23562-8d9b-49dd-aee6-dadd3438bdc0') center / cover" }}   >

       <div className="text-center container-mid ">
       <div className="pt-5"></div>

       {emailSended && (
        <div className='alert_sending' >
          <p className='about_info_subheadline' style={ { fontFamily: determineStyle('para') } } >
            {sucess == 1 ?  'Your Email Has Been Sent Successfuly ✅' : 'Error Your Email Has Not Been Sent Successfuly ❌' }
           </p>
        </div>
       )}
        <h1
        data-aos="fade"
          data-aos-easing="linear"
          data-aos-mirror="true"
          data-aos-once="false"
        className="headline_regsyr hover-1" style={ { fontFamily: determineStyle('heading') } }  > {langs[localStorage.getItem("language")][6].title } </h1>
        <div className="mt-3"></div>
        <div className="d-grid grid_contact">
          <div className="g-col-2">

            <div className="grid__one">
            <img
            data-aos="fade-right"
          data-aos-easing="ease-in-cubic"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-anchor-placement="top-center"
             src="https://i.ibb.co/98WpnW4/contact-image.jpg" className='box avatar_regsyr_big avatar_contact' alt="" />
            </div>
            <div className="grid__two">

            <h1 className="subheadline_regsyr_small hover-1" style={ { fontFamily: determineStyle('heading') } } > {langs[localStorage.getItem("language")][6].subtitle } </h1>
            <p className="mt-1 mb-2 lead_para" style={ { fontFamily: determineStyle('para') } } >
            {langs[localStorage.getItem("language")][6].desc }
            </p>

            <form ref={form} onSubmit={sendEmail}  >

        <div className="mt-1"></div>

        <div className="form_contact">
        <label htmlFor="user_name" className='input_label' style={ { fontFamily: determineStyle('para') } } > {langs[localStorage.getItem("language")][6].contact_1 } </label>
              <input value={nameText} name="to_name" type="text" className="input_contact" onChange={(e) => setNameText(e.target.value)} />
            </div>

        <div className="form_contact">
        <label htmlFor="user_email" className='input_label' style={ { fontFamily: determineStyle('para') } } > {langs[localStorage.getItem("language")][6].contact_2 } </label>
              <input value={emailText} name="from_name" type="text" className="input_contact" onChange={(e) => setEmailText(e.target.value)} />
            </div>
        <div className="form_contact">
        <label htmlFor="message" className='input_label' style={ { fontFamily: determineStyle('para') } } > {langs[localStorage.getItem("language")][6].contact_3 } </label>
              <textarea name="message" type="text" className="input_contact textarea_input" onChange={(e) => setMessageText(e.target.value)} value={messageText} />
            </div>
        <div className="form_contact">
       {emailSended ? (
        <img src="/images/loading.gif" className='loading_spinner' alt="" />
       ): (
        <input className="about_me_btn_small hover-1" style={ { fontFamily: determineStyle('heading') } } type="submit" value={langs[localStorage.getItem("language")][6].contact_btn } />
       )}





            </div>


        </form>




            </div>
          </div>
        </div>
       </div>
    </div>
  )
}

export default ContactMe