import React , {useState , useEffect} from 'react';
import './HeroArea.css';
import langs from '../languages';
import Aos from 'aos';
import "aos/dist/aos.css";

import { GitHub , Twitter , Instagram  , YouTube , LinkedIn  , WhatsApp , CloseRounded } from '@material-ui/icons';
import determineStyle from '../utils';



const HeroArea = () => {
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const [menuShow , setMenuShow] = useState(false);

  useEffect(() => {
    Aos.init( {  duration: 1200 } )
  } , []);


  return (

    <div id="hero" className='hero_main' style={{ height: '100vh' , background: "linear-gradient(180deg, rgb(15, 15, 15 , 0.8) 0%, rgba(14, 20, 18, 0.8) 100%) ,url('https://firebasestorage.googleapis.com/v0/b/notregsyrdev.appspot.com/o/wallpaperflare.com_wallpaper.jpg?alt=media&token=a3f23562-8d9b-49dd-aee6-dadd3438bdc0') center / cover" }}   >


    { /* MIDDLE AREA  */}

      <div className="middle_regsyr flex-col">

        <img
        data-aos="flip-up"
          data-aos-easing="linear"
          data-aos-mirror="true"
          data-aos-once="false"
         src="https://firebasestorage.googleapis.com/v0/b/notregsyrdev.appspot.com/o/photo1709591897.png?alt=media&token=3de718c9-ad18-46d6-9f80-e5502dff60c9" className='box avatar_regsyr' alt="" />
        <h1
          data-aos="fade"
          data-aos-delay="80"
          data-aos-easing="linear"
          data-aos-mirror="true"
          data-aos-once="false"
         className="headline_regsyr hover-2" style={ { fontFamily: determineStyle('heading') } } >  {langs[localStorage.getItem("language")][1].name} </h1>
        <h1
                  data-aos="fade"
          data-aos-delay="50"
          data-aos-easing="linear"
          data-aos-mirror="true"
          data-aos-once="false"
         className="subheadline_regsyr hover-1 " style={ { fontFamily: determineStyle('heading') , marginTop: '0.5rem' } } > {langs[localStorage.getItem("language")][1].job} </h1>
        <div className="d-flex-c  icons_section ">

        <Twitter className='grounded-radiants' onClick={() => openInNewTab('https://twitter.com/dev_ammarhamdi')} />

        <LinkedIn onClick={() => openInNewTab('https://www.linkedin.com/in/ammar-hammouch-462a50235/')} />

        <GitHub onClick={() => openInNewTab('https://github.com/NotSyrRegDev')} />

        <WhatsApp onClick={() => openInNewTab('https://api.whatsapp.com/send/?phone=966590100548')} />

        <img src="/images/cv-w.png" onClick={() => openInNewTab('https://firebasestorage.googleapis.com/v0/b/notregsyrdev.appspot.com/o/MyCv.pdf?alt=media&token=23700a3f-17f7-49bc-b19c-80c8eddbd33f') } alt="" className="icon_category" />

      </div>
      </div>





    </div>
  )
}

export default HeroArea