import React from 'react';
import {   BrowserRouter,   Route,   Routes } from "react-router-dom";
import Home from './pages/Home';
// import Dashboard from "./admin/scenes/dashboard";

// import AddProject from './admin/scenes/projects/AddProject';
// import EditProject from './admin/scenes/projects/EditProject';
// import DeleteProject from './admin/scenes/projects/DeleteProject';
// import Orders from './admin/scenes/orders/Orders';
// import Contacts from './admin/scenes/contacts/Contacts';
// import AdminProjects from './admin/scenes/projects/AdminProjects';




const App = () => {
  return (
    <div>

      <BrowserRouter>


      <Routes>

      { /* HOME WEBSITE */ }

      <Route path="/" element={<Home />} />

      { /* ADMIN DASHBOARD */ }
      {/* <Route path="/admin" element={<Dashboard />} /> */}

      { /* Projects DASHBOARD */ }
      {/* <Route path="/admin/projects" element={<AdminProjects />} />
      <Route path="/admin/addProject" element={<AddProject />} />
      <Route path="/admin/editProject:/projectId" element={<EditProject />} />
      <Route path="/admin/deleteProject:/projectId" element={<DeleteProject />} /> */}

      { /* Orders DASHBOARD */ }

      {/* <Route path="/admin/orders" element={<Orders />} /> */}

      { /* Contacts DASHBOARD */ }
{/*
      <Route path="/admin/contacts" element={<Contacts />} /> */}

      </Routes>
      </BrowserRouter>

    </div>
  )
}

export default App