import React , {useState , useEffect} from 'react';
import Slider from "react-slick";
import { db , getDocs  , collection , getDoc , doc  } from '../firebase';
import { CloseRounded } from '@material-ui/icons';
import './Projects.css';
import langs from '../languages'
import determineStyle from '../utils'
import Aos from 'aos'
import "aos/dist/aos.css"


const Projects = () => {

  const [proejcts , setProjects ] = useState([]);
  const [popupProjectShow , setPopupProjectShow] = useState(false);
  const [singlePorject , setSingleProject] = useState({});

  const showPopup = async (id) => {


    const docRef = doc(db, "projects", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setSingleProject(docSnap.data());

    }

    setPopupProjectShow(!popupProjectShow);
  }

  const fetchAll = async () => {
    const querySnapshot = await getDocs(collection(db, "projects"));

    const projectsDataArray = querySnapshot.docs
    .map((doc) => ({ ...doc.data(), id: doc.id }));


    setProjects(projectsDataArray);


  }

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  useEffect(() => {
    fetchAll();

  } , []);

  useEffect(() => {
    Aos.init( {  duration: 1200 } )
  } , []);

    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1250,
      autoplaySpeed: 1250,
      cssEase: "linear",
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
              autoplay: true,
              speed: 1250,
              autoplaySpeed: 1250,
              cssEase: "linear",
            }
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              autoplay: true,
              speed: 3000,
              autoplaySpeed: 3000,
              cssEase: "linear",
            }
          },

        ]
      };



  return (
    <div id="projects" className='projects_me_page' style={{ height: 'auto' , background: "linear-gradient(180deg, rgb(0 ,0 ,0 , 0.6) 0%, rgba(25, 56, 59, 0.5) 100%) ,url('https://firebasestorage.googleapis.com/v0/b/notregsyrdev.appspot.com/o/wallpaperflare.com_wallpaper.jpg?alt=media&token=a3f23562-8d9b-49dd-aee6-dadd3438bdc0') center / cover" }} >

        <div className="text-center container-mid">
        <div className="pt-5"></div>
        <h1
        data-aos="flip-up"
          data-aos-easing="linear"
          data-aos-mirror="true"
          data-aos-once="false"
         className="headline_regsyr hover-1" style={ { fontFamily: determineStyle('heading') } }  > {langs[localStorage.getItem("language")][3].title } </h1>

        <div className="pt-5"></div>
        <Slider className='over-hidden' style={{ paddingBottom: '3.5rem' }} {...settings} >

      {proejcts ? proejcts.map(({    demo_link , id  , github_link , tachnologies , thumbnail , title }) => (

      <div className="main_card" key={id} >
            <div className="project_single_card">
                    <div>
                    <img src={thumbnail} alt="" className="card_img_card" onClick={() => showPopup(id) } key={title} />
                    </div>
            </div>
            <div className="flex-col-c mt-1 card_info">
            <h1 className="subheadline_regsyr_small " style={ { fontFamily: determineStyle('heading')  } } > {title} </h1>
            <div className="icons_div_card mt-1">
            {tachnologies ? tachnologies.map((  item) => (

                <img key={item} src={`/images/${item}.png`}  alt="" className='icons_build'  />


            )) : '' }



            </div>

            <button  onClick={() => openInNewTab(demo_link) }  className="about_me_btn_small mt-1 hover-1" style={ { fontFamily: determineStyle('heading')  , cursor: 'pointer'} }  > رؤية المشروع </button>
            </div>
            <img src="/images/github-link.png" alt="" className="github_preview" onClick={() => openInNewTab(github_link)} />
            <img src="/images/viewport.png" alt="" className="live_preview"  onClick={() => openInNewTab(demo_link)} />
            </div>
    )) : '' }

        </Slider>

        {popupProjectShow && (
            <div className="popup_project_single box  ">
            <div className="main_card" onClick={() => showPopup(singlePorject.id) } key={singlePorject.title} >


            <div className="project_single_card">
                    <div>
                    <img src={singlePorject.thumbnail} alt="" className="card_img_card" />
                    </div>
            </div>
            <div className="flex-col-c mt-1 card_info">
            <h1 className="subheadline_regsyr_small "> {singlePorject.title.substring(0 , 20)} </h1>
            <p className="mt-1  lead_para">
            {singlePorject.description}
            </p>
            <div className="icons_div_card mt-1">
            {singlePorject.tachnologies ? singlePorject.tachnologies.map((  item) => (

                <img key={item} src={`/images/${item}.png`}  alt="" className='icons_build'  />


            )) : '' }

            </div>
            </div>
            <img src="/images/github-link.png" alt="" className="github_preview" onClick={() => openInNewTab(singlePorject.github_link)} />
            <img src="/images/viewport.png" alt="" className="live_preview"  onClick={() => openInNewTab(singlePorject.demo_link)} />
            </div>

            <CloseRounded onClick={() => setPopupProjectShow(!setPopupProjectShow) } />

              </div>
          )  }


        </div>
    </div>
  )
}

export default Projects