import React , {useEffect} from 'react';
import './MyExpereince.css';
import langs from '../languages';
import determineStyle from '../utils';
import Aos from 'aos';
import "aos/dist/aos.css";

const MyExpereince = () => {

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  useEffect(() => {
    Aos.init( {  duration: 1200 } )
  } , []);

  return (
    <div id="myexpereince" className='services_page' style={{ height: 'auto' , paddingBottom: '3.5rem' ,  background: "linear-gradient(180deg, rgb(20, 23, 23 , 0.8) 0%, rgba(28, 38, 38, 0.5) 100%) ,url('https://firebasestorage.googleapis.com/v0/b/notregsyrdev.appspot.com/o/wallpaperflare.com_wallpaper.jpg?alt=media&token=a3f23562-8d9b-49dd-aee6-dadd3438bdc0') center / cover" }}   >

    <div className="text-center container-sm">
    <div className="pt-5"></div>
     <h1
     data-aos="flip-up"
          data-aos-easing="linear"
          data-aos-mirror="true"
          data-aos-once="false"
      className="headline_regsyr hover-1" style={ { fontFamily: determineStyle('heading') } }  > خبراتي </h1>
     <div className="mt-5"></div>
     <div className="d-grid grid_hire">
       <div className="g-col-2">



         <div className="grid__two">

         <div className="flex-col-c hire_icons">
         <img
         data-aos="fade-right"
          data-aos-easing="ease-in-cubic"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-anchor-placement="top-center"
          src="https://i.ibb.co/9tsqLc0/IMG-1338.jpg" className='box avatar_regsyr_small experince_avatar avatar_hire' alt="" />


         </div>

         <h1 className="mt-1 subheadline_regsyr_small hover-1" style={ { fontFamily: determineStyle('heading') } }  > على مدار السنين الفائتة عملت على العديد من التقنيات واللغات البرمجية...  </h1>



         </div>

         <div className="grid__one experince_section">

<div className="experince_flex icons_section mt-2">

<img data-aos="fade" data-aos-delay="60" data-aos-easing="linear" data-aos-mirror="true"  src="/images/technolgoies/html-5.png" />

<img data-aos="fade" data-aos-delay="60" data-aos-easing="linear" data-aos-mirror="true"  src="/images/technolgoies/css-3.png" />

<img data-aos="fade" data-aos-delay="60" data-aos-easing="linear" data-aos-mirror="true"  src="/images/technolgoies/js.png" />

<img data-aos="fade" data-aos-delay="60" data-aos-easing="linear" data-aos-mirror="true"  src="/images/technolgoies/react.png" />

<img data-aos="fade" data-aos-delay="60" data-aos-easing="linear" data-aos-mirror="true"  src="/images/technolgoies/firebase.png" />

<img data-aos="fade" data-aos-delay="60" data-aos-easing="linear" data-aos-mirror="true"  src="/images/technolgoies/php.png" />

<img data-aos="fade" data-aos-delay="60" data-aos-easing="linear" data-aos-mirror="true"  src="/images/technolgoies/mysql.png" />

<img data-aos="fade" data-aos-delay="60" data-aos-easing="linear" data-aos-mirror="true"  src="/images/technolgoies/laravel.png" />

<img data-aos="fade" data-aos-delay="60" data-aos-easing="linear" data-aos-mirror="true"  src="/images/technolgoies/node-js.png" />

<img data-aos="fade" data-aos-delay="60" data-aos-easing="linear" data-aos-mirror="true"  src="/images/technolgoies/express-js.png" />

<img data-aos="fade" data-aos-delay="60" data-aos-easing="linear" data-aos-mirror="true"  src="/images/technolgoies/mongodb.png" />

<img data-aos="fade" data-aos-delay="60" data-aos-easing="linear" data-aos-mirror="true"  src="/images/technolgoies/python.png" />

<img data-aos="fade" data-aos-delay="60" data-aos-easing="linear" data-aos-mirror="true"  src="/images/technolgoies/sql-lite.png" />

<img data-aos="fade" data-aos-delay="60" data-aos-easing="linear" data-aos-mirror="true"  src="/images/technolgoies/mongodb.png" />

<img data-aos="fade" data-aos-delay="60" data-aos-easing="linear" data-aos-mirror="true"  src="/images/technolgoies/wit-ai.png" />


</div>





</div>

       </div>
     </div>
    </div>
 </div>
  )
}

export default MyExpereince