import React from 'react';
import determineStyle from '../../utils';

const SharingThree = () => {
  return (
    <>

    <div className="grid__two">

<div className="flex-col-c hire_icons">
<img
data-aos="fade-right"
data-aos-easing="ease-in-cubic"
data-aos-mirror="true"
data-aos-once="false"
data-aos-anchor-placement="top-center"
src="https://pbs.twimg.com/media/FrH--kbWcBQYJpq?format=jpg&name=large" className='box  avatar_regsyr_horzietal avatar_hire' alt="" />


</div>


   </div>

      <div className="grid__one">

      <div className="single_done_div ">

        <div className="flex_done_end">
        <div className="bordered_line"></div>
        <div className="bordered_circular"></div>
        </div>

        <div>
        <h1 className="mt-1 subheadline_regsyr_small hover-1 " style={ { fontFamily: determineStyle('heading') } }  >    المشاركة في هاكثون تيك مييت اب 3 </h1>

        <p className="mt-3 mb-2 lead_para" style={ { fontFamily: determineStyle('para') } }  >
        شاركت مع فريقي في تصميم وبرمجة تطبيق انقاذ في هاكثون جده ومناقشته مع لجنة التحكيم في هاثون جده تيك مييت اب 3

        </p>
        </div>

        </div>

      </div>


    </>
  )
}

export default SharingThree