
    const langs = [

        // ENGLISH
        [

        {
                'key': 'Header',
                'name': 'Ammar Hammouch',
                'job': 'Software Developer',
                'list1': 'Home',
                'list2': 'About',
                'list3': 'Projects',
                'list4': 'Services',
                'list5': 'Hire Me',
                'list6': 'Contact Me',
                'list7': 'Change Language',
                'siteName': 'NotRegSyrDev',
        },
        {
            'key': 'HerArea',
            'name': 'Ammar Hammouch',
            'job': 'Software Develoepr',
        },
        {
            'key': 'About',
            'title': 'ABOUT ME',
            'desc': 'Ammar Hammouch, programmer above all, a computer science student at Jazan University, full stack web developer, partner in many technical initiatives, passionate about learning and volunteer work, contributor to the dissemination of technical content in Arabic',
            'about_1': 'Name',
            'about_2': 'Degree',
            'about_3': 'Phone',
            'about_4': 'Address',
            'value_1': 'Ammar Hammouch',
            'value_2': 'Computer Science',
            'value_3': '+966 55 498 4459',
            'value_4': 'Jazan Schema 6',
        },
        {
            'key': 'Projects',
            'title' : 'Proejcts',
        },
        {
            'key': 'Services',
            'title': 'Services',
            'service_1': 'Mobile App Development',
            'service_2': 'Web App Development',
            'service_3': 'SEO Development',

            'service_1_desc' : 'I will create custom mobile applications using flutter technology , testing the application prior to final review , research and develop mobile Android , IOS apps to serve users',

            'service_2_desc': 'I will develope responsive , modern web apps using technologies like (Node.Js , Laravel , React) and popular cms Like (Wordpress , Shopify ) , provide security maintenance and patching on website interface to maintain viability after launch , converting mockups into usable web presence.',

            'service_3_desc': 'I will recommended changes to website architecture, content and linking to improve SEO positions analyzed SEO outreach goals and presented findings to marketing director.'
        },

        {
            'key': 'Hire Me',
            'title': 'Hire Me',
            'subtitle': 'Building Your Next Project...',
            'desc': 'Available any time for freelancing work ! , Here you can hire me on any freelancing platform you are trust and comfortable working with.'
        },
        {
            'key': 'Contact Me',
            'title': 'Contact Me',
            'subtitle': 'Let\'s Have A Connection',
            'desc': 'Let me have more details about your next project , ه will provide you with technical advice via e-mail for your project , let\'s make this world better by letting your ideas flow into real world applications !.',
            'contact_1' : 'Full Name: ',
            'contact_2' : 'Email: ',
            'contact_3' : 'Message: ',
            'contact_btn' : 'CONTACT ME',

        }

        ],

        // ARABIC
        [
            {
                'key': 'Header',
                'name': 'عمار حموش',
                'job': 'مهندس برمجيات',
                'list1': 'الرئيسية',
                'list2': 'عني',
                'list3': 'مشاريعي',
                'list4': 'خدماتي',
                'list5': 'وظفني',
                'list6': 'تواصل معي',
                'list7': 'تغيير اللغة',
                'siteName': 'NotRegSyrDev',
        },
        {
            'key': 'HerArea',
            'name': 'عمار حموش',
            'job': 'مهندس برمجيات',
        },
        {
            'key': 'About',
            'title': 'معلومات عني',
            'desc': 'عمار حموش مبرمج قبل كل شئ , طالب علوم حاسب في جامعة جازان , فول ستاك مطور ويب , اطور تطبيقات فلاتر للاندرويد والايفون , شريك في العديد من المبادرات التقنية , شغوف بالتعلم والعمل التطوعي , مساهم في نشر المحتوى التقني بالعربي',
            'about_1': 'الاسم',
            'about_2': 'التخصص',
            'about_3': 'الرقم',
            'about_4': 'العنوان',
            'value_1': 'عمار حموش',
            'value_2': 'علوم حاسب',
            'value_3': '+966 55 498 4459',
            'value_4': 'جازان مخطط 6',
        },
        {
            'key': 'Projects',
            'title' : 'المشاريع',
        },
        {
            'key': 'Services',
            'title': 'الخدمات',
            'service_1': 'تطوير تطبيقات الهاتف',
            'service_2': 'تطوير تطبيقات الويب',
            'service_3': 'تطوير محركات البحث',

            'service_1_desc' : 'أقوم بتطوير تطبيقات مخصصة للافراد والشركات باستخدام اطار عمل فلاتر , والبحث وتطوير التطبيق على منصتي الاندرويد و الايفون للاجهزة المحمولة لخدمة المستخدمين , واختبار التطبيق قبل المراجعة النهائية',

            'service_2_desc': 'أقوم بتطوير تطبيقات الويب السريعة والتي تعمل على جميع الاجهزة باستخدام تقنيات حديثة مثل (نود جي اس , ولارفيل ورياكت) , كما اقوم بتنظيم وادارة انظمة ادارة المحتوى مثل (وورد بريس , وشوبيفاي) وتوفير صيانة امنية وضمان سرعة لموقع الويب',

            'service_3_desc': 'أقوم بتحسين بنية موقع الويب ومحتوياته ليتم تحسين ظهور موقع الويب على محرك البحث , تحليل الاهداف و تحسين محركات البحث وتقديم النتائج لمدير التسويق'
        },

        {
            'key': 'Hire Me',
            'title': 'وظفني',
            'subtitle': '...بناء مشروعك القادم',
            'desc': 'متاح في اي وقت للعمل الحر ! , هنا يمكنك اختياري لبناء مشروعك القادم على اي منصة للعمل الحر تثق بها وترتاح في التعامل معها'
        },
        {
            'key': 'Contact Me',
            'title': 'تواصل معي',
            'subtitle': 'ليكن بيننا تواصل',
            'desc': 'دعني اعرف المزيد عن تفاصيل مشروعك القادم , اقدم لك استشارات تقنية عن طريق الايميل لمشروعك القادم , ولنبدا بجعل العالم افضل من خلال تحويل افكارك لمشاريع حقيقية',
            'contact_1' : ':الاسم الكامل',
            'contact_2' : ':الايميل',
            'contact_3' : ':محتوى الرسالة',
            'contact_btn' : 'تواصل معي',

        }
        ]



    ];

    export default langs;