import React from 'react';
import { Menu , CloseRounded } from '@material-ui/icons';
import langs from '../languages';
import determineStyle from '../utils';
import {Link} from 'react-scroll';

const Header = (  ) => {




    const changeLangSite = () => {


      if ( localStorage.getItem('language') == 1 ) {

        localStorage.setItem('language', 0 );
      }
      else if ( localStorage.getItem('language') == 0 ) {

        localStorage.setItem('language', 1 );
      }
      window.location.reload();
    }



  return (
    <div>

        <div className="menu_mobile_show">
          <div className="closing_div">
            <CloseRounded onClick={() => {
               document.querySelector('.menu_mobile_show').classList.toggle('active');
            } }  />
          </div>
              <div className="pt-2 text-center mobile_header">
              <img src="https://firebasestorage.googleapis.com/v0/b/notregsyrdev.appspot.com/o/photo1709591897.png?alt=media&token=3de718c9-ad18-46d6-9f80-e5502dff60c9" className='box avatar_regsyr_small rounded_full' alt="" />
        <h1 className="headline_regsyr hover-2" style={ { fontFamily: determineStyle('heading') } } > {  langs[localStorage.getItem('language')][0].name    } </h1>
        <h1 className="subheadline_regsyr hover-1" style={ { fontFamily: determineStyle('heading') } } > {  langs[localStorage.getItem('language')][0].job    } </h1>
          <ul className="menu_mobile flex-col mt-1">
            <li   >
            <Link to="hero" spy={true} smooth={true} offset={50} duration={500}  >
            {  langs[localStorage.getItem('language')][0].list1    }
        </Link>
            </li>

            <li  >
            <Link to="about" spy={true} smooth={true} offset={50} duration={500}  >
            {  langs[localStorage.getItem('language')][0].list2    }
        </Link>
           </li>

            <li>
            <Link to="projects" spy={true} smooth={true} offset={50} duration={500}  >
            {  langs[localStorage.getItem('language')][0].list3    }
        </Link>
           </li>

            <li>
            <Link to="myexpereince" spy={true} smooth={true} offset={50} duration={500}  >
           خبراتي
        </Link>
           </li>

            <li>
            <Link to="mysharings" spy={true} smooth={true} offset={50} duration={500}  >
           مشاركاتي
        </Link>
           </li>

            <li>
            <Link to="services" spy={true} smooth={true} offset={50} duration={500}  >
            {  langs[localStorage.getItem('language')][0].list4    }
        </Link>
            </li>

            <li  >
            <Link to="hire" spy={true} smooth={true} offset={50} duration={500}  >
            {  langs[localStorage.getItem('language')][0].list5    }
        </Link>
            </li>

            <li >
            <Link to="contact" spy={true} smooth={true} offset={50} duration={500}  >
            {  langs[localStorage.getItem('language')][0].list6    }
        </Link>
            </li>

            <li onClick={() => changeLangSite() } >

           <a href="#">
           {  langs[localStorage.getItem('language')][0].list7    }
           </a>

            </li>
          </ul>
              </div>
        </div>

    { /* TOP HEADER */}
    <div className="top_header_regsyr">
      <h1> {  langs[localStorage.getItem('language')][0].siteName    } </h1>

     <Menu onClick={() => {

      document.querySelector('.menu_mobile_show').classList.toggle('active');
     } } />

    </div>
    </div>
  )
}

export default Header