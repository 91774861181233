import React , {useEffect} from 'react';
import './HireMe.css';
import langs from '../languages';
import determineStyle from '../utils';
import Aos from 'aos';
import "aos/dist/aos.css";



const HireMe = () => {

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
      };


  useEffect(() => {
    Aos.init( {  duration: 1200 } )
  } , []);

  return (
    <div id="hire" className='services_page' style={{ height: 'auto' ,  paddingBottom: '3.5rem' ,  background: "linear-gradient(180deg, rgb(20, 23, 23 , 0.8) 0%, rgba(28, 38, 38, 0.5) 100%) ,url('https://firebasestorage.googleapis.com/v0/b/notregsyrdev.appspot.com/o/wallpaperflare.com_wallpaper.jpg?alt=media&token=a3f23562-8d9b-49dd-aee6-dadd3438bdc0') center / cover" }}   >

    <div className="text-center container-mid">
    <div className="pt-5"></div>
     <h1
     data-aos="flip-up"
          data-aos-easing="linear"
          data-aos-mirror="true"
          data-aos-once="false"
      className="headline_regsyr hover-1" style={ { fontFamily: determineStyle('heading') } }  > {langs[localStorage.getItem("language")][5].title }  </h1>
     <div className="mt-5"></div>
     <div className="d-grid grid_hire">
       <div className="g-col-2">

         <div className="grid__one">
         <img
         data-aos="fade-right"
          data-aos-easing="ease-in-cubic"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-anchor-placement="top-center"
          src="/images/freelance-image.png" className='box avatar_regsyr_big avatar_hire' alt="" />
         </div>
         <div className="grid__two">

         <h1 className="subheadline_regsyr_small hover-1" style={ { fontFamily: determineStyle('heading') } }  > {langs[localStorage.getItem("language")][5].subtitle }  </h1>
         <p className="mt-1 mb-1 lead_para" style={ { fontFamily: determineStyle('para') } }  >
         {langs[localStorage.getItem("language")][5].desc }
         </p>

         <div className="flex-col-c hire_icons">
         <div className="d-flex-c icons_section">

<img
          data-aos="fade"
          data-aos-delay="20"
          data-aos-easing="linear"
          data-aos-mirror="true"

 src="/images/mostqal.jpg" className='grounded-radiants' onClick={() => openInNewTab('https://mostaql.com/u/Ammar_Hamdi')} />
<img
          data-aos="fade"
          data-aos-delay="40"
          data-aos-easing="linear"
          data-aos-mirror="true"
 src="/images/khamsat.png" onClick={() => openInNewTab('https://khamsat.com/user/notregsyrdev')} />


</div>

<div className="d-flex-c icons_section">
         <img
          data-aos="fade"
          data-aos-delay="60"
          data-aos-easing="linear"
          data-aos-mirror="true"

          src="/images/upwork.png" onClick={() => openInNewTab('https://www.upwork.com/freelancers/~01a072897e3e6f14a8')} />
<img
          data-aos="fade"
          data-aos-delay="80"
          data-aos-easing="linear"
          data-aos-mirror="true"
 src="/images/people-per-hour.png" onClick={() => openInNewTab('https://www.youtube.com/c/RedAmmar1')} />
<img
          data-aos="fade"
          data-aos-delay="100"
          data-aos-easing="linear"
          data-aos-mirror="true"
 src="/images/freelancer.png" onClick={() => openInNewTab('https://www.peopleperhour.com/freelancer/ammar-hammouch-web-developer-mazaqnx')} />

</div>


         </div>








         </div>
       </div>
     </div>
    </div>
 </div>
  )
}

export default HireMe;