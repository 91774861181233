
const determineStyle = (param) => {

    if (param == 'heading') { 

      if (localStorage.getItem("language")  == 0 )  {
        return "Gemunu Libre";
      }
      else {
        return "Readex Pro";
      }
      
    }

    if (param == 'para' ) {

        if (localStorage.getItem("language")  == 0 )  {
            return "Quicksand";
          }
          else {
            return "Tajawal";
          }
      
          
    }

    if (param == 'regular' ) {

        if (localStorage.getItem("language")  == 0 )  {
            return "Varela Round";
          }
          else {
            return "Baloo Bhaijaan 2";
          }
      
          
    }
    
  }

  export default determineStyle