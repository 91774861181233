import { initializeApp } from "firebase/app";
import { collection , getFirestore , getDocs , query , doc, getDoc , where , setDoc } from "firebase/firestore";
import {  onAuthStateChanged  , signInWithEmailAndPassword , getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STOARGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_ID,
    appId: process.env.REACT_APP_APP_ID
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();

export { db , collection , getDocs , query , doc, getDoc , onAuthStateChanged , signInWithEmailAndPassword , auth  , where , setDoc }

export default app;