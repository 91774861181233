import React , {useState , useEffect} from 'react';
import { CloseRounded } from '@material-ui/icons';
import './Services.css';
import langs from '../languages';
import determineStyle from '../utils';
import Aos from 'aos';
import "aos/dist/aos.css";


const Services = () => {

  const [popupMobileShow , setPopupMobileShow] = useState(false);
  const [popupWebShow , setPopupWebShow] = useState(false);
  const [popupSeoShow , setPopupSeoShow] = useState(false);

  useEffect(() => {
    Aos.init( {  duration: 1200 } )
  } , []);

  const mobileShowText = langs[localStorage.getItem("language")][4].service_1_desc   ;


  const webShowText = langs[localStorage.getItem("language")][4].service_2_desc  ;

  const seoShowText = langs[localStorage.getItem("language")][4].service_3_desc  ;

  const hideAll = () => {
    setPopupMobileShow(false);
    setPopupWebShow(false);
    setPopupSeoShow(false);
  }

  const determineShow = (popupShow) => {

    if (popupShow == 'mobile') {
      setPopupMobileShow(!popupMobileShow);
      setPopupWebShow(false);
      setPopupSeoShow(false);

    }

    if (popupShow == 'web') {
      setPopupMobileShow(false);
      setPopupWebShow(!popupWebShow);
      setPopupSeoShow(false);

    }

    if (popupShow == 'seo') {
      setPopupMobileShow(false);
      setPopupWebShow(false);
      setPopupSeoShow(!popupSeoShow);

    }

   }

  return (
    <div id="services" className='services_page' style={{ height: '100vh' , background: "linear-gradient(180deg, rgb(0 ,0 ,0 , 0.6) 0%, rgba(25, 56, 59, 0.5) 100%) ,url('https://firebasestorage.googleapis.com/v0/b/notregsyrdev.appspot.com/o/wallpaperflare.com_wallpaper.jpg?alt=media&token=a3f23562-8d9b-49dd-aee6-dadd3438bdc0') center / cover" }}   >

        <div className="flex-col container-mid">

        <h1
        data-aos="fade"
          data-aos-easing="linear"
          data-aos-mirror="true"
          data-aos-once="false"

        className="headline_regsyr hover-1" style={ { fontFamily: determineStyle('heading') } }  > {langs[localStorage.getItem("language")][4].title }  </h1>
      <div className="mt-3"></div>
          <div className="serives_regsur f-wrap d-flex-c">

            <div
            data-aos="flip-right"
            data-aos-delay="50"
          data-aos-easing="linear"
          data-aos-mirror="true"
          data-aos-once="false"
           className="box mt-1 single-service flex-col-c" onClick={ () => determineShow('mobile') } >
              <img src="/images/mobile-icon.png" alt="" className="service_icon" />
              <h1 className="subheadline_regsyr_small" style={ { fontFamily: determineStyle('heading') } }  > {langs[localStorage.getItem("language")][4].service_1 }  </h1>



            </div>

            <div
            data-aos="fade"
            data-aos-delay="80"
          data-aos-easing="linear"
          data-aos-mirror="true"
          data-aos-once="false"
             className="box mt-1 single-service flex-col-c" onClick={ () => determineShow('web') } >
              <img src="/images/web-icon.png" alt="" className="service_icon" />
              <h1 className="subheadline_regsyr_small" style={ { fontFamily: determineStyle('heading') } }  > {langs[localStorage.getItem("language")][4].service_2 }  </h1>
            </div>

            <div
            data-aos="flip-left"
            data-aos-delay="100"
          data-aos-easing="linear"
          data-aos-mirror="true"
          data-aos-once="false"
             className="box mt-1 single-service flex-col-c" onClick={ () => determineShow('seo')  } >
              <img src="/images/seo-icon.png" alt="" className="service_icon" />
              <h1 className="subheadline_regsyr_small" style={ { fontFamily: determineStyle('heading') } }  > {langs[localStorage.getItem("language")][4].service_3 }  </h1>
            </div>

          </div>

          {popupMobileShow && (
            <div className="popup_service_single box ">
            <img src="/images/mobile-icon.png" alt="" className="service_icon" />
              <p className='about_info_subheadline' style={ { fontFamily: determineStyle('para') } }  > {mobileShowText} </p>
              <CloseRounded onClick={ () => hideAll() } />
              </div>
          )  }

          {popupWebShow && (
            <div className="popup_service_single box ">
            <img src="/images/web-icon.png" alt="" className="service_icon" />
              <p className='about_info_subheadline' style={ { fontFamily: determineStyle('para') } }  > {webShowText} </p>
              <CloseRounded onClick={ () => hideAll() } />
              </div>
          )  }

          {popupSeoShow && (
            <div className="popup_service_single box ">
            <img src="/images/seo-icon.png" alt="" className="service_icon" />
              <p className='about_info_subheadline' style={ { fontFamily: determineStyle('para') } }  > {seoShowText} </p>
              <CloseRounded onClick={ () => hideAll() } />
              </div>
          )  }


        </div>

    </div>
  )
}

export default Services